@-webkit-keyframes bounceInUp {
    from,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
        transform: translate3d(0, 3000px, 0) scaleY(5)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
        transform: translate3d(0, -20px, 0) scaleY(0.9)
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
        transform: translate3d(0, 10px, 0) scaleY(0.95)
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
        transform: translate3d(0, -5px, 0) scaleY(0.985)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes bounceInUp {
    from,60%,75%,90%,to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
        transform: translate3d(0, 3000px, 0) scaleY(5)
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
        transform: translate3d(0, -20px, 0) scaleY(0.9)
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
        transform: translate3d(0, 10px, 0) scaleY(0.95)
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
        transform: translate3d(0, -5px, 0) scaleY(0.985)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@-webkit-keyframes backInDown {
    0% {
        -webkit-transform: translateY(-1200px) scale(0.7);
        transform: translateY(-1200px) scale(0.7);
        opacity: 0.7
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes backInDown {
    0% {
        -webkit-transform: translateY(-1200px) scale(0.7);
        transform: translateY(-1200px) scale(0.7);
        opacity: 0.7
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes backInLeft {
    0% {
        -webkit-transform: translateX(-2000px) scale(0.7);
        transform: translateX(-2000px) scale(0.7);
        opacity: 0.7
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes backInLeft {
    0% {
        -webkit-transform: translateX(-2000px) scale(0.7);
        transform: translateX(-2000px) scale(0.7);
        opacity: 0.7
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes backInRight {
    0% {
        -webkit-transform: translateX(2000px) scale(0.7);
        transform: translateX(2000px) scale(0.7);
        opacity: 0.7
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes backInRight {
    0% {
        -webkit-transform: translateX(2000px) scale(0.7);
        transform: translateX(2000px) scale(0.7);
        opacity: 0.7
    }

    80% {
        -webkit-transform: translateX(0px) scale(0.7);
        transform: translateX(0px) scale(0.7);
        opacity: 0.7
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@keyframes flipInX {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
    }

    to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px)
    }
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3)
    }

    50% {
        opacity: 1
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3)
    }

    50% {
        opacity: 1
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}