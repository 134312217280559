@font-face {
    font-family: 'Iskra';
    src: url(../fonts/Iskra-UltraBold.woff2) format("woff2");
    font-weight: 800;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Muller';
    src: url(../fonts/MullerBlack.woff2) format("woff2");
    font-weight: 900;
    font-style: normal
}

@font-face {
    font-family: 'Muller';
    src: url(../fonts/MullerExtraBold.woff2) format("woff2");
    font-weight: 800;
    font-style: normal
}

@font-face {
    font-family: 'Muller';
    src: url(../fonts/MullerBold.woff2) format("woff2");
    font-weight: bold;
    font-style: normal
}

@font-face {
    font-family: 'Muller';
    src: url(../fonts/MullerRegular.woff2) format("woff2");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Muller';
    src: url(../fonts/MullerRegularItalic.woff2) format("woff2");
    font-weight: normal;
    font-style: italic
}
